<template>
<div>
    <v-row>
        <v-col cols="12">
            <v-card class="mx-auto">
                <v-img src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg" height="200px"></v-img>

                <v-card-title>
                    Collecte des données sur le projet
                </v-card-title>
                <v-card-actions>
                    <v-btn dark color="orange lighten-2">
                        Enregistrer Tout
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn dark color="red lighten-2" @click="$router.back()">
                        Retour
                    </v-btn>

                </v-card-actions>

            </v-card>
        </v-col>
        <v-col md="12">
            <transition-group appear name="fade-up" tag="div">
                <v-data-iterator key :items="items" :items-per-page.sync="itemsPerPage" :page="page" hide-default-footer>
                    <template v-slot:default="props">
                        <v-row>
                            <v-col v-for="(item, index) in props.items" :key="index" cols="12" sm="6" md="6" lg="4">
                                <base-card>
                                    <v-card-text>
                                        <div class="d-flex justify-space-between align-center flex-wrap mb-4">
                                            <div class="d-flex align-center">
                                                <div>
                                                    <p class="ma-0">
                                                        <a href="#" class="text--primary font-weight-medium mb-1 hover:info--text">
                                                            {{ item.title }}
                                                        </a>
                                                    </p>

                                                </div>
                                            </div>

                                        </div>
                                        <v-divider />
                                        <div class="d-flex justify-space-between align-center flex-wrap mt-4">
                                            <div class="mb-2">
                                                <div class="d-flex justify-space-between">
                                                    <p class="pr-10">
                                                        {{ item.parametrage_projet.libelle_parametre_projets }}
                                                    </p>

                                                </div>
                                                <v-col cols="12" sm="12" md="12" lg="12" v-if="(item.parametrage_projet.composant.nom_web=='v-text-field'?true:false)">
                                                    <v-text-field v-model="item.valeur" outlined></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="12" lg="12" v-if="(item.parametrage_projet.composant.nom_web=='v-file-input'?true:false)">
                                                    <v-file-input v-model="item.valeur" outlined></v-file-input>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="12" lg="12" v-if="(item.parametrage_projet.composant.nom_web=='v-textarea'?true:false)">
                                                    <v-textarea v-model="item.valeur" outlined></v-textarea>
                                                </v-col>

                                            </div>
                                            <div class="d-flex justify-end">
                                                <row>

                                                    <v-btn class="ma-2" color="green" small depressed @click="save_one()">
                                                        Enregistrer
                                                    </v-btn>
                                                </row>

                                            </div>
                                        </div>
                                    </v-card-text>
                                </base-card>
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:footer>
                        <v-row class="mt-2" align="center" justify="center">
                            <v-spacer />

                            <span class="mr-4 grey--text">Page {{ page }} of
                                {{ numberOfPages }}</span>
                            <v-btn small fab dark color="primary darken-3" class="mr-1" @click="formerPage">
                                <v-icon>mdi-chevron-left</v-icon>
                            </v-btn>
                            <v-btn small fab dark color="primary darken-3" class="ml-1" @click="nextPage">
                                <v-icon>mdi-chevron-right</v-icon>
                            </v-btn>
                        </v-row>
                    </template>
                </v-data-iterator>
            </transition-group>
        </v-col>
    </v-row>
</div>
</template>

<script>
//import ListCard from '@/components/card/listCard/ListCard'
import api from '../../../../serviceApi/apiService'

export default {
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'ListColumn One'
    },
    components: {
        //      'list-card': ListCard
    },
    data() {
        return {
            itemsPerPageArray: [],
            search: '',
            filter: {},
            items_modale: [],
            sortDesc: false,
            page: 1,
            itemsPerPage: 12,
            sortBy: 'name',
            keys: [
                'Name',
                'Calories',
                'Fat',
                'Carbs',
                'Protein',
                'Sodium',
                'Calcium',
                'Iron'
            ],
            items: [],
        }
    },
    computed: {
        numberOfPages() {
            return Math.ceil(this.items.length / this.itemsPerPage)
        },
        filteredKeys() {
            return this.keys.filter((key) => key !== 'Name')
        }
    },
    methods: {

        save_one: async function () {

            let fd = new FormData();
            fd.append('items', JSON.stringify(this.items));

            const data = await api.createUpdatedata('projet/update-collecte-projet', fd);
            if (data.status == 200) {
                console.log(data.message)
                this.readAll();
            } else {

            }
        },

        readAll: async function () {
            //let fd = new FormData();
            //fd.append('id_institutions', '1');
            //const data = await api.readAll('projet/list-collecte-projet/8399d73a-27ac-48e6-b57d-6b026df46f7d');
            // console.log(this.$route.params.idprojet);
            const data = await api.readAll('projet/list-collecte-projet/' + this.$route.params.idprojet);
            this.items = data;

        },

        saveAll: async function () {

            // const data = await api.readAll('souscription/verifie-souscription-odd-extrat');
            let fd = new FormData();
            fd.append('matricule', this.items);
            const data = await api.createUpdatedata('projet/update-collecte-projet/8399d73a-27ac-48e6-b57d-6b026df46f7d', fd);
            this.list = data.list;
            this.listinfo = data.list_info;
            if (data.status == 200) {

            } else {

            }
        },

        nextPage() {
            if (this.page + 1 <= this.numberOfPages) this.page += 1
        },
        formerPage() {
            if (this.page - 1 >= 1) this.page -= 1
        },
        updateItemsPerPage(number) {
            this.itemsPerPage = number
        }
    },
    mounted() {
        this.readAll();

    },
}
</script>

<style lang="scss">
.fade-up-enter {
    opacity: 0;
    transform: translateY(40px);
}

.fade-up-enter-active {
    transition: all 0.4s ease;
}
</style>
